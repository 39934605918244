.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@font-face {
  font-family: "Gilroy-Bold";
  src: url(./fonts/Gilroy-Bold.ttf);
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url(./fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url(./fonts/Gilroy-Regular.ttf);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
  /* animation: bg-animation .2s infinite; */
  opacity: .9;
  visibility: visible;
}
@keyframes bg-animation {
    0% { transform: translate(0,0) }
    10% { transform: translate(-5%,-5%) }
    20% { transform: translate(-10%,5%) }
    30% { transform: translate(5%,-10%) }
    40% { transform: translate(-5%,15%) }
    50% { transform: translate(-10%,5%) }
    60% { transform: translate(15%,0) }
    70% { transform: translate(0,10%) }
    80% { transform: translate(-15%,0) }
    90% { transform: translate(10%,5%) }
    100% { transform: translate(5%,0) }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  
}

html, body {
  height: 100%;
}


body{
  background-color: #111111;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  color-scheme: dark only;
}
.bodyCenter{
  max-width: 580px;
  text-align: left;
}


.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
}

.line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.line::after {
  content: '';
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  animation: drop 5s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.line:nth-child(1) {
  margin-left: -30%;
}

.line:nth-child(1)::after {
  animation-delay: 0s;
}

.line:nth-child(3) {
  margin-left: 30%;
}

.line:nth-child(3)::after {
  animation-delay: 0.5s;
}

@keyframes drop {
  0% {
    top: -20%;
  }
  100% {
    top: 110%;
  }
}
.content{
  color: #e3e3e3;
  font-size: 20px;
  font-family: Gilroy-Medium;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  line-height: 28px;
  word-wrap: break-word;
  margin-top: 50px;
  text-align: left;
}
.contentText{
  margin-top: 35px;
}
.logo{
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.copy{
  text-align: center;
  color: rgba(255, 255, 255, 0.70);
  font-size: 14px;
  font-family: Gilroy-Medium;
  font-weight: 300;
  line-height: 16.80px;
  word-wrap: break-word;
  margin-bottom: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}
@media (max-width: 900px) {
  .content{
    padding:40px;
    margin-top: 30px;
  }
  .copy{
    padding:0 20px;
    position: relative;
    bottom: 0;
    padding-bottom: 30px;
    margin-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .bodyCenter{
    max-width: 100%;
    min-height: 100vh;
    position: relative;
  }
  .line:nth-child(1) {
    margin-left: -48%;
  }
  .line:nth-child(3) {
    margin-left: 48%;
  }
}






/* .outline {
  font-family: "Geist Mono", monospace;
  color: canvasText;
  padding: 1rem 2rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s 1s, scale 0.5s 3s, opacity 0s 3s;
  --intent: 1;
}




.outline > span {
  position: relative;
  color: transparent;
}

.outline > span::after {
  content: attr(data-char);
  position: absolute;
  display: inline-block;
  inset: 0;
  color: canvasText;
  animation: flash 1s steps(1, end);

}

@keyframes flash {
  0%, 20%   { content: var(--char-0); }
  40%  { content: var(--char-1); }
  60%  { content: var(--char-2); }
  80%  { content: var(--char-3); }
} */













